import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent } from './components/ui/card';
import { Label } from '@radix-ui/react-label';
import { ChevronUp, ChevronDown, X } from 'lucide-react';
import { Button } from './components/ui/button';
// Removemos la importación de Separator si no se está utilizando
// import { Separator } from '@/components/ui/separator';

const CustomNumberInput = ({ value, onChange, step = 0.01, isShares = false, readOnly = false, allowNegative = false }) => {
  const [digits, setDigits] = useState({
    d100: '0',
    d10: '0',
    d1: '0',
    dec10: '0',
    dec1: '0'
  });

  useEffect(() => {
    if (isShares) {
      const strValue = Math.abs(value).toString();
      setDigits({
        d100: '0',
        d10: '0',
        d1: strValue,
        dec10: '0',
        dec1: '0'
      });
    } else {
      const [intPart, decPart = '00'] = value.toString().split('.');
      const paddedInt = intPart.padStart(3, '0');
      const paddedDec = decPart.padEnd(2, '0');

      setDigits({
        d100: paddedInt[0],
        d10: paddedInt[1],
        d1: paddedInt[2],
        dec10: paddedDec[0],
        dec1: paddedDec[1]
      });
    }
  }, [value, isShares]);

  const handleDigitChange = (digit, delta) => {
    if (isShares) {
      let newValue = parseInt(value || 0) + (delta * 100);
      if (!allowNegative) {
        newValue = Math.max(0, newValue);
      }
      onChange(newValue);
      return;
    }

    const newDigits = { ...digits };
    let current = parseInt(newDigits[digit] || '0');
    current += delta;

    // Handle carrying over and borrowing
    if (digit === 'dec1') {
      if (current > 9) {
        current = 0;
        handleDigitChange('dec10', 1);
      } else if (current < 0) {
        current = 9;
        handleDigitChange('dec10', -1);
      }
    } else if (digit === 'dec10') {
      if (current > 9) {
        current = 0;
        handleDigitChange('d1', 1);
      } else if (current < 0) {
        current = 9;
        handleDigitChange('d1', -1);
      }
    } else if (digit === 'd1') {
      if (current > 9) {
        current = 0;
        handleDigitChange('d10', 1);
      } else if (current < 0) {
        current = 9;
        handleDigitChange('d10', -1);
      }
    } else if (digit === 'd10') {
      if (current > 9) {
        current = 0;
        handleDigitChange('d100', 1);
      } else if (current < 0) {
        current = 9;
        handleDigitChange('d100', -1);
      }
    } else if (digit === 'd100') {
      if (current > 9) current = 9;
      if (current < 0) current = 0;
    }

    newDigits[digit] = current.toString();
    const newValue = `${newDigits.d100}${newDigits.d10}${newDigits.d1}.${newDigits.dec10}${newDigits.dec1}`;
    onChange(parseFloat(newValue));
  };

  const renderDigitControl = (digit, position) => (
    <div className="flex flex-col items-center">
      {!readOnly && (
        <button
          onClick={() => handleDigitChange(digit, 1)}
          className="h-4 w-4 flex items-center justify-center text-gray-400 hover:text-gray-600 transition-colors"
        >
          <ChevronUp size={10} />
        </button>
      )}
      <span className="my-1 text-sm font-medium">{digits[digit]}</span>
      {!readOnly && (
        <button
          onClick={() => handleDigitChange(digit, -1)}
          className="h-4 w-4 flex items-center justify-center text-gray-400 hover:text-gray-600 transition-colors"
        >
          <ChevronDown size={10} />
        </button>
      )}
    </div>
  );

  if (isShares) {
    return (
      <div className="relative flex items-center">
        <input
          type="text"
          value={value}
          readOnly
          className="w-24 h-10 px-2 border rounded-md text-center"
        />
        {!readOnly && (
          <div className="absolute right-1 top-0 bottom-0 flex flex-col justify-center">
            <button
              onClick={() => handleDigitChange('d1', 1)}
              className="h-5 w-5 flex items-center justify-center text-gray-400 hover:text-gray-600 transition-colors"
            >
              <ChevronUp size={14} />
            </button>
            <button
              onClick={() => handleDigitChange('d1', -1)}
              className="h-5 w-5 flex items-center justify-center text-gray-400 hover:text-gray-600 transition-colors"
            >
              <ChevronDown size={14} />
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative flex items-center bg-white border rounded-md px-2 py-1 space-x-1">
      {renderDigitControl('d100')}
      {renderDigitControl('d10')}
      {renderDigitControl('d1')}
      <span className="mx-1">.</span>
      {renderDigitControl('dec10')}
      {renderDigitControl('dec1')}
    </div>
  );
};

const ResetButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="ghost"
    size="icon"
    className="h-8 w-8 p-0 ml-1"
  >
    <X size={14} />
  </Button>
);

const TradingCalculator = () => {
  const [state, setState] = useState({
    entryPrice: '000.00',
    entryShares: 0,
    currentPrice: '000.00',
    stopShort: '000.00',
    stopLong: '000.00',
    longPositions: Array(3).fill({ price: '000.00', shares: 0 }),
    shortPositions: Array(3).fill({ price: '000.00', shares: 0 }),
    longTakeProfits: Array(3).fill({ price: '000.00', shares: 0 }),
    shortTakeProfits: Array(3).fill({ price: '000.00', shares: 0 }),
    totalShares: 0,
    currentShares: 0,
    averagePrice: '000.00',
    openPL: '000.00',
    closedPL: '000.00',
    totalPL: '000.00',
    losses: '000.00',
    isShortPosition: false,
    riskUnit: '000.00'
  });

  const parsePrice = useCallback((price) => {
    return parseFloat(price);
  }, []);

  const formatPrice = useCallback((number) => {
    return number.toFixed(2);
  }, []);

  const calculateResults = useCallback(() => {
    const entry = parsePrice(state.entryPrice);
    const shares = state.entryShares;
    const current = parsePrice(state.currentPrice);
    const stopPrice = state.isShortPosition ? parsePrice(state.stopShort) : parsePrice(state.stopLong);

    let totalInvested = entry * Math.abs(shares);
    let totalShares = Math.abs(shares);
    let currentShares = shares;
    let closedPL = 0;

    const calculatePositions = (positions, takeProfits, isShort) => {
      positions.forEach(pos => {
        if (pos.shares !== 0) {
          totalInvested += parsePrice(pos.price) * Math.abs(pos.shares);
          totalShares += Math.abs(pos.shares);
          currentShares += pos.shares;
        }
      });

      takeProfits.forEach(tp => {
        if (tp.shares !== 0) {
          const tpPrice = parsePrice(tp.price);
          const tpShares = Math.abs(tp.shares);
          totalShares += tpShares; // Añadir los shares de TP al total
          if (isShort) {
            closedPL += (entry - tpPrice) * tpShares;
            currentShares += tpShares;
          } else {
            closedPL += (tpPrice - entry) * tpShares;
            currentShares -= tpShares;
          }
        }
      });
    };

    if (state.isShortPosition) {
      calculatePositions(state.shortPositions, state.shortTakeProfits, true);
    } else {
      calculatePositions(state.longPositions, state.longTakeProfits, false);
    }

    const averagePrice = totalShares !== 0 ? totalInvested / totalShares : 0;
    
    let openPL = 0;
    if (state.isShortPosition) {
      openPL = (entry - current) * Math.abs(currentShares);
    } else {
      openPL = (current - entry) * currentShares;
    }

    const totalPL = openPL + closedPL;
    const losses = Math.max(0, -totalPL);

    // Cálculo de la Risk Unit (RU)
    const riskPerShare = Math.abs(entry - stopPrice);
    const riskUnit = riskPerShare * Math.abs(shares);

    return {
      totalShares,
      currentShares,
      averagePrice: formatPrice(averagePrice),
      openPL: formatPrice(openPL),
      closedPL: formatPrice(closedPL),
      totalPL: formatPrice(totalPL),
      losses: formatPrice(losses),
      riskUnit: formatPrice(riskUnit)
    };
  }, [state, parsePrice, formatPrice]);

  useEffect(() => {
    const results = calculateResults();
    setState(prev => ({ ...prev, ...results }));
  }, [state.entryPrice, state.entryShares, state.currentPrice, state.stopShort, state.stopLong, state.longPositions, state.shortPositions, state.longTakeProfits, state.shortTakeProfits, state.isShortPosition, calculateResults]);

  const updatePosition = useCallback((type, index, field, value) => {
    setState(prev => {
      const newState = { ...prev };
      newState[`${type}Positions`] = [...prev[`${type}Positions`]];
      newState[`${type}Positions`][index] = {
        ...newState[`${type}Positions`][index],
        [field]: value
      };
      return newState;
    });
  }, []);

  const updateTakeProfit = useCallback((type, index, field, value) => {
    setState(prev => {
      const newState = { ...prev };
      newState[`${type}TakeProfits`] = [...prev[`${type}TakeProfits`]];
      if (field === 'shares') {
        if (type === 'long') {
          value = -Math.abs(value); // Asegura que los shares sean siempre negativos para TP de posiciones largas
        } else {
          value = Math.abs(value); // Mantiene los shares positivos para TP de posiciones cortas
        }
      }
      newState[`${type}TakeProfits`][index] = {
        ...newState[`${type}TakeProfits`][index],
        [field]: value
      };
      return newState;
    });
  }, []);

  const resetAll = useCallback(() => {
    setState({
      entryPrice: '000.00',
      entryShares: 0,
      currentPrice: '000.00',
      stopShort: '000.00',
      stopLong: '000.00',
      longPositions: Array(3).fill({ price: '000.00', shares: 0 }),
      shortPositions: Array(3).fill({ price: '000.00', shares: 0 }),
      longTakeProfits: Array(3).fill({ price: '000.00', shares: 0 }),
      shortTakeProfits: Array(3).fill({ price: '000.00', shares: 0 }),
      totalShares: 0,
      currentShares: 0,
      averagePrice: '000.00',
      openPL: '000.00',
      closedPL: '000.00',
      totalPL: '000.00',
      losses: '000.00',
      isShortPosition: false,
      riskUnit: '000.00'
    });
  }, []);

  const resetInput = useCallback((key, defaultValue) => {
    setState(prev => ({ ...prev, [key]: defaultValue }));
  }, []);

  return (
    <div className="relative">
      {/* Floating Results Bar */}
      <div className="fixed top-0 left-0 right-0 bg-white p-4 shadow-md z-10">
        <div className="max-w-6xl mx-auto flex justify-between items-end">
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Total Shares</Label>
            <span className="font-bold text-lg">{state.totalShares}</span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Current Shares</Label>
            <span className="font-bold text-lg">{state.currentShares}</span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Average Price</Label>
            <span className="font-bold text-lg">{state.averagePrice}</span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Open P&L</Label>
            <span className={`font-bold text-lg ${parseFloat(state.openPL) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              ${Math.abs(parseFloat(state.openPL)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Closed P&L</Label>
            <span className={`font-bold text-lg ${parseFloat(state.closedPL) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              ${Math.abs(parseFloat(state.closedPL)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Total P&L</Label>
            <span className={`font-bold text-lg ${parseFloat(state.totalPL) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              ${Math.abs(parseFloat(state.totalPL)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">Losses</Label>
            <span className="font-bold text-lg text-red-600">
              ${parseFloat(state.losses).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
          <div className="flex flex-col items-center">
            <Label className="text-sm font-medium text-gray-500">RU</Label>
            <span className="font-bold text-lg text-gray-600"> {/* Update: Changed text color to gray */}
              ${parseFloat(state.riskUnit).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
          <Button onClick={resetAll} variant="outline" size="sm">Reset All</Button>
        </div>
      </div>

      {/* Main Content */}
      <Card className="w-full max-w-6xl p-6 mt-20">
        <CardContent>
          <div className="grid grid-cols-1 gap-4">
            {/* Long Positions - Green Background */}
            <div className="bg-green-50 p-4 rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  {state.longPositions.map((pos, index) => (
                    <div key={`long-${index}`} className="flex items-center gap-4 mb-4">
                      <Label className="w-12">Add</Label>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={pos.price}
                          onChange={(val) => updatePosition('long', index, 'price', val)}
                        />
                        <ResetButton onClick={() => updatePosition('long', index, 'price', '000.00')} />
                      </div>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={pos.shares}
                          onChange={(val) => updatePosition('long', index, 'shares', val)}
                          isShares={true}
                          allowNegative={true}
                        />
                        <ResetButton onClick={() => updatePosition('long', index, 'shares', 0)} />
                      </div>
                      <span className="text-sm text-gray-500">Shares</span>
                    </div>
                  ))}
                </div>
                <div className="border-l border-green-200 pl-4">
                  {state.longTakeProfits.map((tp, index) => (
                    <div key={`long-tp-${index}`} className="flex items-center gap-4 mb-4">
                      <Label className="w-12">TP</Label>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={tp.price}
                          onChange={(val) => updateTakeProfit('long', index, 'price', val)}
                        />
                        <ResetButton onClick={() => updateTakeProfit('long', index, 'price', '000.00')} />
                      </div>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={tp.shares}
                          onChange={(val) => updateTakeProfit('long', index, 'shares', val)}
                          isShares={true}
                          allowNegative={true}
                        />
                        <ResetButton onClick={() => updateTakeProfit('long', index, 'shares', 0)} />
                      </div>
                      <span className="text-sm text-gray-500">Shares</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Stop Short */}
            <div className="flex items-center gap-4 py-2">
              <Label className="w-16">Stop</Label>
              <CustomNumberInput
                value={state.stopShort}
                onChange={(val) => setState(prev => ({ ...prev, stopShort: val }))}
              />
              <ResetButton onClick={() => resetInput('stopShort', '000.00')} />
            </div>

            {/* Entry Level */}
            <div className="flex items-center gap-4 border-y py-4">
              <Label className="w-16">Entry</Label>
              <div className="flex items-center">
                <CustomNumberInput
                  value={state.entryPrice}
                  onChange={(val) => setState(prev => ({ ...prev, entryPrice: val }))}
                />
                <ResetButton onClick={() => resetInput('entryPrice', '000.00')} />
              </div>
              <div className="flex items-center ml-8">
                <Label className="w-16">Shares</Label>
                <div className="flex items-center">
                  <CustomNumberInput
                    value={state.entryShares}
                    onChange={(val) => setState(prev => ({ ...prev, entryShares: val, isShortPosition: val < 0 }))}
                    isShares={true}
                    allowNegative={true}
                  />
                  <ResetButton onClick={() => resetInput('entryShares', 0)} />
                </div>
                <div className="ml-8 flex items-center">
                  <Label className="w-24">Current Price</Label>
                  <div className="flex items-center">
                    <CustomNumberInput
                      value={state.currentPrice}
                      onChange={(val) => setState(prev => ({ ...prev, currentPrice: val }))}
                    />
                    <ResetButton onClick={() => resetInput('currentPrice', '000.00')} />
                  </div>
                </div>
              </div>
            </div>

            {/* Stop Long */}
            <div className="flex items-center gap-4 py-2">
              <Label className="w-16">Stop</Label>
              <CustomNumberInput
                value={state.stopLong}
                onChange={(val) => setState(prev => ({ ...prev, stopLong: val }))}
              />
              <ResetButton onClick={() => resetInput('stopLong', '000.00')} />
            </div>

            {/* Short Positions - Red Background */}
            <div className="bg-red-50 p-4 rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  {state.shortPositions.map((pos, index) => (
                    <div key={`short-${index}`} className="flex items-center gap-4 mb-4">
                      <Label className="w-12">Add</Label>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={pos.price}
                          onChange={(val) => updatePosition('short', index, 'price', val)}
                        />
                        <ResetButton onClick={() => updatePosition('short', index, 'price', '000.00')} />
                      </div>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={pos.shares}
                          onChange={(val) => updatePosition('short', index, 'shares', val)}
                          isShares={true}
                          allowNegative={true}
                        />
                        <ResetButton onClick={() => updatePosition('short', index, 'shares', 0)} />
                      </div>
                      <span className="text-sm text-gray-500">Shares</span>
                    </div>
                  ))}
                </div>
                <div className="border-l border-red-200 pl-4">
                  {state.shortTakeProfits.map((tp, index) => (
                    <div key={`short-tp-${index}`} className="flex items-center gap-4 mb-4">
                      <Label className="w-12">TP</Label>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={tp.price}
                          onChange={(val) => updateTakeProfit('short', index, 'price', val)}
                        />
                        <ResetButton onClick={() => updateTakeProfit('short', index, 'price', '000.00')} />
                      </div>
                      <div className="flex items-center">
                        <CustomNumberInput
                          value={Math.abs(tp.shares)}
                          onChange={(val) => updateTakeProfit('short', index, 'shares', val)}
                          isShares={true}
                        />
                        <ResetButton onClick={() => updateTakeProfit('short', index, 'shares', 0)} />
                      </div>
                      <span className="text-sm text-gray-500">Shares</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TradingCalculator;